// import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { valiDate } from "../../helper";
import useReactRouter from "use-react-router";

export default function ListModel() {
  const { history } = useReactRouter();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [phone, setPhoneNumber] = useState(0);
  return (
    <>
      <React.Fragment>
        <button
          type="button"
          className="btn btn-success btn-block btn-lg"
          onClick={() => setShow(true)}
        >
          <i className="fas fa-solid fa-arrow-right-to-bracket me-2" />
          ເບິ່ງຂໍ້ມູນການຮ້ອງຮຽນ
        </button>
        <Modal
          centered
          show={show}
          onHide={() => setShow(false)}
          animation={false}
          backdrop="static"
          size="lg"
        >
          <Modal.Header>
            <Modal.Title className="fs-5">
              <i className="icon-sliders" /> ເບິ່ງຂໍ້ມູນຮ້ອງຮຽນ
            </Modal.Title>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => handleClose()}
              style={{ textAlign: "right" }}
            >
              <i className="icon-x" style={{ marginRight: 3 }} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="col-12 mb-2">
                <label className="mt-2">
                  ປ້ອນເບີໂທເພື່ອອ້າງອີງ{valiDate()}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="55xxx55"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="col-4 mb-2">
                {phone > 0 ? (
                  <button
                    type="button"
                    className="btn btn-success mb-2"
                    onClick={() => history.push("/xwEum8a0x2dws5a0ws/" + phone)}
                  >
                    <i className="fas fa-solid fa-arrow-right-to-bracket me-2" />
                    ເຂົ້າເບິ່ງຂໍ້ມູນ
                  </button>
                ) : (
                  <button
                    type="button"
										disabled
                    className="btn btn-success mb-2"
                    onClick={() => history.push("/xwEum8a0x2dws5a0ws/" + phone)}
                  >
                    <i className="fas fa-solid fa-arrow-right-to-bracket me-2" />
                    ເຂົ້າເບິ່ງຂໍ້ມູນ
                  </button>
                )}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
}
