//upload file to google storage
export const gcpUpload = async ({ file, bucketName, pathname, filename }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucketName", bucketName);
      formData.append("pathname", pathname);
      formData.append("filename", filename);
      // const res = await fetch("https://pro.api.anousith.express/google/upload", {
        const res = await fetch("https://api.services.anousith.express/google/upload", {
        method: "POST",
        body: formData,
      });
      const resWithBody = await res.json();
      return resWithBody;
    } catch (error) {
      throw new Error(error);
    }
  };