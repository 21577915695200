import { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import AddCircleOutlineTwoToneIcon from "@material-ui/icons/AddCircleOutlineTwoTone";
import {messageError, messageSuccess } from "../../helper";
import { url_upload_file } from "../../helper/url";
import { gcpUpload } from "../../helper/upload";

export default function UploadIconControl({ onSuccess, profileImage }) {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [newFilename, setNewFilename] = useState("");
  const inputRef = useRef();
  useEffect(() => {
    setNewFilename(profileImage);
  }, [profileImage, show]);
  const handleClick = () => {
    inputRef.current.value = "";
    setFile(null);
    setNewFilename("");
    inputRef.current.click();
  };
  const handleUpload = async () => {
    try {
      setUploading(true);
      const res = await gcpUpload({
        file: file,
        bucketName: "anousith-bucket",
        pathname: "feedback_images",
        filename: newFilename,
      });
      
      if (res?.publicUrl) {
        messageSuccess("ການດຳເນິນງານສຳເລັດແລ້ວ");
        setShow(false);
        if (onSuccess) {
          onSuccess(res);
        }
      } else {
        messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
      }
      setUploading(false);
    } catch (error) {
      console.log(error);
      messageError("ການດຳເນິນງານບໍ່ສຳເລັດ");
      setUploading(false);
    }
  };
  return (
    <div className="mb-3">
      <label className="mb-1">
        ເລືອກຮູບພາບ
      </label>
      <div>
        {newFilename ? (
          <img
            className="w-100 rounded"
            // style={{width: 300, height: 260}}
            src={`${url_upload_file}/${newFilename}`}
            onClick={() => {
              setShow(true);
            }}
          />
        ) : (
          <AddCircleOutlineTwoToneIcon
            size={80}
            onClick={() => {
              setShow(true);
            }}
          />
        )}
        <input
          type="file"
          ref={inputRef}
          accept="image/png, image/jpg, image/jpeg, image/svg"
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
          hidden
        />
      </div>

      <Modal show={show}>
        <Modal.Header
          closeButton
          onHide={() => {
            if (!uploading) {
              setShow(false);
            }
          }}
        >
          <AddCircleOutlineTwoToneIcon size={25} className="me-1" />
          {newFilename || "---"}
        </Modal.Header>
        <Modal.Body>
          <label className="text-danger text-small">
            <em>*  ກົດໃສ່ໄອຄອນ ຫຼື ເຄື່ອງໝາຍບວກ ເພື່ອເລືອກຮູບພາບໃໝ່.</em>
          </label>
          <div className="text-center">
            {newFilename ? (
              <img
                className="border rounded p-2"
                src={`${url_upload_file}/${profileImage}`}
                style={{ maxWidth: 200, maxHeight: 200 }}
                alt=""
                onClick={handleClick}
              />
            ) : (
              <>
                {!file ? (
                  <AddCircleOutlineTwoToneIcon size={180} onClick={handleClick} />
                ) : (
                  <img
                    className="w-100 rounded p-2"
                    src={URL.createObjectURL(file)}
                    // style={{ maxWidth: 400, maxHeight: 400 }}
                    alt=""
                    onClick={handleClick}
                  />
                )}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary shadow-none"
            disabled={!file || uploading}
            onClick={handleUpload}
          >
            {uploading ? (
              <Spinner animation="border" variant="white" size="sm" />
            ) : (
              <AddCircleOutlineTwoToneIcon />
            )}
            <span className="ms-1">ອັບໂຫລ</span>
            
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
