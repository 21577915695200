// import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { valiDate } from "../../helper";
import useReactRouter from "use-react-router";
import ListModel from "./ListMode";

export default function FeedBack() {
  const { history } = useReactRouter();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="container-main mt-3">
        <div className="card">
          <div className="row">
            <div className="col-md-2" />
            <div className=" col-md-8">
              <div className="card " >
                <div className="card-body bg-secondary" >
                  <h2 style={{color:'white'}}>ການຮ້ອງຮຽນບັນຫາ</h2>
                </div>
                <div className="row mt-3 mb-2">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-primary btn-block btn-lg"
                      onClick={() => history.push("/FormFeedBack/")}
                    >
                      <i className="icon-plus" />
                      ເພິ່ມການຮ້ອງຮຽນ
                    </button>
                  </div>
                  <div className="col-md-6">
                    <ListModel />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </div>
    </>
  );
}
